.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, #5848ea 0%, #9549eb 100%);
  color: white;
}
.page-not-found-container .pnf-internal {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.page-not-found-container h1 {
  font-size: 10rem;
  font-weight: 900;
}
.page-not-found-container h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 20px;
}
.page-not-found-container button {
    background: #fff;
    color: #5848ea;
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* media query (medium devices) */
@media screen and (max-width:800px) {
    .page-not-found-container h1 {
        font-size: 8rem;
    }
    .page-not-found-container h2 {
        font-size: 2.5rem;
    }
    .page-not-found-container button {
        font-size: 1.3rem;
    }
    
}

/* media query (small devices) */
@media screen and (max-width:600px) {
    .page-not-found-container .pnf-internal {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
    .page-not-found-container h1 {
        font-size: 6rem;
    }
    .page-not-found-container h2 {
        font-size: 2rem;
        margin-top: 0;
        position: relative;
        top: -15px;
    }
    .page-not-found-container button {
        font-size: 1.1rem;
    }
}
