.statistics-container {
  max-width: 1000px;
  margin: auto;
  position: relative;
  top: 100px;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 50px 0;
  border-radius: 1.5rem;
  z-index: 5;
}
.statistics-container .top h2 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.statistics-container .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 150px;
  margin-left: 150px;
}
.statistics-container .bottom .item {
  font-size: 1.4rem;
  text-align: center;
}

.statistics-container .bottom .border {
  height: 50px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.184);
}

/* media query (small laptop devices) */
@media screen and (max-width: 1150px) {
  .statistics-container {
    max-width: 800px;
  }
  .statistics-container .top h2 {
    font-size: 2rem;
  }
  .statistics-container h2 {
    font-size: 2rem;
  }
  .statistics-container .span-class {
    font-size: 1.4rem;
  }
  .statistics-container .bottom {
    margin-right: 100px;
    margin-left: 100px;
  }
}

/* media query (small devices) */
@media screen and (max-width: 700px) {
  .statistics-container {
    max-width: 600px;
  }
  .statistics-container .top h2 {
    font-size: 1.6rem;
  }
  .statistics-container h2 {
    font-size: 1.6rem;
  }
  .statistics-container .bottom {
    margin-right: 30px;
    margin-left: 30px;
  }
}

/* media query (extra small devices) */
@media screen and (max-width: 500px) {
  .statistics-container .top h2 {
    font-size: 1.4rem;
  }
  .statistics-container h2 {
    font-size: 1.4rem;
  }
  .statistics-container .bottom {
    margin-right: 10px;
    margin-left: 10px;
  }
  .statistics-container .span-class {
    font-size: 1.2rem;
  }
}
