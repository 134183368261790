.campus-captains-outer-container {
  width: 100vw;
  background: var(--primary-bg);
  padding-bottom: 100px;
  color: var(--primary-color);
  padding-top: 100px;
}

.campus-captains-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: var(--secondary-bg);
  width: 90%;
  max-width: 1200px;
  margin: auto;
  /* margin-top: 100px; */
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 1.4rem;
}
.campus-captains-container .left {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 2rem;
  width: 60%;
  margin-left: 40px;
}
.campus-captains-container h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
}
.campus-captains-container .left p {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.campus-captains-container .left button {
  padding: 1rem 3rem;
  border-radius: 50px;
  color: var(--secondary-color);
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: 500;
  width: max-content;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}
.campus-captains-container .left button:hover {
  transform: scale(1.05);
}
.campus-captains-container .left button .campus-captain-button {
  color: white;
}
.campus-captains-container .right {
  width: 40%;
}
.campus-captains-container .right img {
  width: 350px;
  animation: wind 5s infinite;
}
@keyframes wind {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

/* media query (medium devices) */
@media screen and (max-width: 1000px) {
  .campus-captains-container {
    flex-direction: column;
    width: 80%;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .campus-captains-container .left {
    width: 100%;
    margin-left: 0;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .campus-captains-container .right {
    width: 350px;
  }
  .campus-captains-container .right img {
    width: 100%;
    margin: auto;
  }
}

/* media query (small devices) */
@media screen and (max-width: 600px) {
  .campus-captains-container {
    width: 90%;
  }
  .campus-captains-container h1 {
    font-size: 2rem;
  }
  .campus-captains-container .left p {
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
  .campus-captains-container .left button {
    font-size: 1.1rem;
    padding: 0.7rem 1.5rem;
  }
}
