.join-discord-server-container {
  background: linear-gradient(90deg, #5848ea 0%, #9549eb 100%);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .join-discord-server-container {
    font-size: 1.5rem;
  }
}
