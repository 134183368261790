.testimonials-outer-container {
    width: 100vw;
    background: var(--primary-bg);
    padding-bottom: 100px;
    color: var(--primary-color);
    padding-top: 100px;
}

.testimonials-container {
}

.card {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg);
    max-width:400px;
    cursor: pointer;
    border-radius: 10px;
    perspective: 100px;
    transition: 0.3s;
  
}
.card:hover
{
  transform: scale(1.05);
  animation-play-state: paused;
}
.star-ratings{
    padding-top:3vh;
    padding-left: 25px;
    
}
.card-content
{
    padding:3vh 25px 1vh 25px;
    color: var(--primary-color);
}
.personal-name {
    display: inline-flex;
    padding-left:25px;
    margin-top: 2vh;
    margin-bottom: 0vh;
}
.personal-name div img
{
   height: 50px;
   width: 50px;
   border-radius: 50px;
}
.inner-personal
{
  height: 50px;
   margin-left: 10px;
   color: var(--headings);
}
.inner-personal h2:nth-child(1)
{
    font-size: 16px;
    margin-top: 0.5vh;
}
.inner-personal h2:nth-child(2)
{
    font-size: 16px;
    margin-top: -0.5vh;
    opacity:0.3;
}
.slider 
{
   
    margin: auto;
    position: relative;
    width: 100%;
    padding-block: 7vh;
    display: grid;
    place-items: center;
    overflow: hidden;
    margin-bottom: 8vh ;
    background: var(--secondary-bg);
    margin-top: 9vh;
}
.slider-track
{
    display: flex;
    justify-content: space-between;
    width: calc(400px*14);
    column-gap: 2rem;  
    animation: scroll 50s linear infinite; 
   margin-top: 0;
}
.slider-track:hover
{
    animation-play-state: paused;
}
@keyframes scroll {
    0%{
        transform: translateX(0);
    }
    100%
    {
        transform:translateX(calc(-400px *7));
    }
}
.slider::before,
.slider::after 
{
    background: red;
    height: 100%;
    position: absolute;
    width:0%;
    z-index: 2;
    content: "";
}
.slider::before
{
    left: 0;
    top: 0;
}
.slider::after 
{
 right: 0;
 top: 0;
 transform: rotateZ(180deg);
}
/* .Carousel-cover
{
    background: var(--whycm-bg);
   
} */
.Carousel-cover h1{
    text-align: center;
    /* margin-top: 5vh !important; */
    padding-top:2vh;
   
    font-family: 'poppins' sans-serif;
    font-size: 40px;
}
.Carousel-cover .carousel-subhead
{
    text-align: center;
    font-size: 20px;
   
    padding-top: 2vh;
}
.Carousel-cover .carousel-subhead span
{
    background: red;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Carousel-cover h1 span{
    background: var(--purple-headings);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width:800px) {
    .testimonials-container h1 {
        font-size: 2rem;
    }
    
}